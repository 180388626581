import axios from 'axios'
import { Message } from 'element-ui'


axios.defaults.baseURL = 'https://api.zuwoojia.com'

// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';


function request(url, type = 'GET', data = {}) {
    return new Promise((resolve, reject) => {
        let option = {
            url,
            method: type,
        }


        if (type.toLowerCase() === 'get') {
            option.params = data
        } else {
            option.data = data
        }

        axios(option)
            .then(res => {
                if (res.statusText === "OK") {
                    resolve(res.data)
                } else {
                    reject(res.data)
                }
            })
            .catch((res) => {
                Message.error('网络异常')
                reject(res)
            })
    })
}

export function postMessage(data) {
    return request('main/303/official/league/post-user-league', 'POST', data)
}

export function getDocList(data) {
    return request(`main/303/official/league/getClientDemandData`, 'POST', data)
}

export function postDoc(data) {
    return request(`main/303/official/league/uploadFiles`, 'POST', data)
}

export function deletDoc(data) {
    return request(`main/303/official/league/deleteClientDemandFile`, 'POST', data)
}